import React from 'react';
import Slide from 'react-reveal/Slide';

import SEO from '../components/seo';

import { Menu, Item } from '../styles/styles.js';

const BatataRecheada = () => (
  <>
    <SEO title="Batata Recheada" />

    <Menu className="menu">
      <Slide left cascade duration={500}>
        <h1 className="menu-title">Batata Recheada</h1>
      </Slide>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 01</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Carne com Catupiry, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 02</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Carne com Requeijão, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 03</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Carne com Cheddar, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 04</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">Carne com Mussarela, Milho e Bacon.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 05</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Palmito com Catupiry, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 06</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Palmito com Requeijão, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 07</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Palmito com Cheddar, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 08</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">Palmito com Mussarela, Milho e Bacon.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 09</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Lombo Canadense com Catupiry, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 10</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Lombo Canadense com Requeijão, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 11</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Lombo Canadense com Cheddar, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 12</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Lombo Canadense com Mussarela, Milho e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 13</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Frango com Catupiry, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 14</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Frango com Requeijão, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 15</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Frango com Cheddar, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 16</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">Frango com Mussarela, Milho e Bacon.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 17</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Calabresa com Catupiry, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 18</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Calabresa com Requeijão, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 19</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Calabresa com Cheddar, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 20</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">Calabresa com Mussarela, Milho e Bacon.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 21</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">44,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Carne Seca com Catupiry, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 22</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">44,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Carne Seca com Requeijão, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 23</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">44,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Carne Seca com Cheddar, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 24</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">44,00</h4>
          </div>
        </div>

        <p className="item-desc">Carne Seca com Mussarela, Milho e Bacon.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 25</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Atum com Catupiry, Cebola, Mussarela e Alho Flocos.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 26</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Atum com Requeijão, Cebola, Mussarela e Alho Flocos.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 27</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Atum com Cheddar, Cebola, Mussarela e Alho Flocos.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 28</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">Atum com Mussarela, Cebola e Alho Flocos.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 29</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Tomate Seco com Catupiry, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 30</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Tomate Seco com Requeijão, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 31</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Tomate Seco com Cheddar, Milho, Mussarela e Bacon.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 32</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">Tomate Seco com Mussarela, Milho e Bacon.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 33</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">38,00</h4>
          </div>
        </div>

        <p className="item-desc">Strogonofre de Carne com Catupiry e Bacon.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 34</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">38,00</h4>
          </div>
        </div>

        <p className="item-desc">Strogonofre de Carne com Requeijão e Bacon.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 35</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">38,00</h4>
          </div>
        </div>

        <p className="item-desc">Strogonofre de Carne com Cheddar e Bacon.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 36</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">38,00</h4>
          </div>
        </div>

        <p className="item-desc">Strogonofre de Carne com Mussarela e Bacon.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 37</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">48,00</h4>
          </div>
        </div>

        <p className="item-desc">Camarão com Catupiry.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 38</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">48,00</h4>
          </div>
        </div>

        <p className="item-desc">Camarão com Requeijão.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 39</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">48,00</h4>
          </div>
        </div>

        <p className="item-desc">Camarão com Cheddar.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 40</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">48,00</h4>
          </div>
        </div>

        <p className="item-desc">Camarão com Mussarela.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 41</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">38,00</h4>
          </div>
        </div>

        <p className="item-desc">
          4 Queijos: Mussarela, Catupiry, Provolone e Gorgonzola.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 42</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">38,00</h4>
          </div>
        </div>

        <p className="item-desc">
          5 Queijos: Mussarela, Catupiry, Provolone, Gorgonzola e Cheddar.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 43</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">38,00</h4>
          </div>
        </div>

        <p className="item-desc">
          6 Queijos: Mussarela, Catupiry, Provolone, Gorgonzola, Cheddar e
          Requeijão.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 44</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Pizza com Catupiry: Queijo, Presunto, Tomate e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 45</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Pizza com Requeijão: Queijo, Presunto, Tomate e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 46</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">34,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Pizza com Cheddar: Queijo, Presunto, Tomate e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">B 47</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">38,00</h4>
          </div>
        </div>

        <p className="item-desc">Brócolis c/ 4 Queijos</p>
      </Item>
    </Menu>
  </>
);

export default BatataRecheada;
